import Layout from "../../../component/layout/Layout";
import { Li } from "../../../component/layout/css/styled.layout";
import { useEffect, useState } from "react";
import { InputBox } from "../../../component/form/Form";
import { useChangeHandler } from "../../../common/CommonUtil";
import { noticeBoardForm } from "./init";
import Editor from "../../../component/board/Editor";
import useBoardService from "../useBoardService";
import { NoticeEditorBoardBox } from "./css/styled.NoticeBoard";
import { Box } from "../../../component/box/css/styled.box";
import SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

function NoticeBoardWrite() {
    const { state, onChangeHandler } = useChangeHandler(noticeBoardForm);
    const { postNoticeBoard } = useBoardService();
    const [content, setContent] = useState();
    const onClickWrite = () => {
        state.userId = sessionStorage.getItem("userId");
        state.noticeBoardContent = content;
        postNoticeBoard(state);
    };
    const button = () => {
        return (
            <Li>
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => onClickWrite()}
                >
                    저장
                </div>
            </Li>
        );
    };

    return (
        <Layout button={button()}>
            <NoticeEditorBoardBox>
                <Box style={{ padding: "0 0 20px 0" }}>
                    <InputBox
                        label="제목"
                        type="text"
                        width="100%"
                        labelWidth="15%"
                        height="50px"
                        id="noticeBoardTitle"
                        value={state.noticeBoardTitle}
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
                <Editor
                    content={content}
                    setContent={setContent}
                    boardType={"notice"}
                />
            </NoticeEditorBoardBox>
        </Layout>
    );

    // const [socket, setSocket] = useState(null);
    // const [messages, setMessages] = useState([]);
    // const [inputMessage, setInputMessage] = useState("");
    // useEffect(() => {
    //     // WebSocket 연결
    //     const ws = new WebSocket("ws://localhost:8000/universal/chat");
    //     // 메시지 수신 시 처리
    //     ws.onmessage = (event) => {
    //         setMessages((prevMessages) => [
    //             ...prevMessages,
    //             `서버: ${event.data}`,
    //         ]);
    //     };
    //     ws.onopen = () => {
    //         console.log("WebSocket 연결 성공");
    //     };
    //     ws.onclose = () => {
    //         console.log("WebSocket 연결 종료");
    //     };
    //     setSocket(ws);
    //     // 컴포넌트 언마운트 시 WebSocket 연결 종료
    //     return () => {
    //         ws.close();
    //     };
    // }, []);
    // // 메시지 전송 함수
    // const sendMessage = () => {
    //     if (socket && inputMessage.trim()) {
    //         setMessages((prevMessages) => [
    //             ...prevMessages,
    //             `사용자: ${inputMessage}`,
    //         ]);
    //         socket.send(inputMessage);
    //         setInputMessage("");
    //     }
    // };
    // return (
    //     <div style={{ padding: "20px", fontFamily: "Arial" }}>
    //         <h1>React WebSocket Chat</h1>
    //         <div
    //             style={{
    //                 border: "1px solid #ccc",
    //                 height: "300px",
    //                 overflowY: "auto",
    //                 padding: "10px",
    //                 marginBottom: "10px",
    //             }}
    //         >
    //             {messages.map((msg, index) => (
    //                 <p key={index} style={{ margin: 0 }}>
    //                     {msg}
    //                 </p>
    //             ))}
    //         </div>
    //         <input
    //             type="text"
    //             value={inputMessage}
    //             onChange={(e) => setInputMessage(e.target.value)}
    //             placeholder="메시지를 입력하세요"
    //             style={{
    //                 padding: "10px",
    //                 width: "calc(100% - 110px)",
    //                 marginRight: "10px",
    //                 border: "1px solid #ccc",
    //                 borderRadius: "4px",
    //             }}
    //         />
    //         <button
    //             onClick={sendMessage}
    //             style={{
    //                 padding: "10px",
    //                 backgroundColor: "#007bff",
    //                 color: "#fff",
    //                 border: "none",
    //                 borderRadius: "4px",
    //                 cursor: "pointer",
    //             }}
    //         >
    //             전송
    //         </button>
    //     </div>
    // );
}

export default NoticeBoardWrite;
